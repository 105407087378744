<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">車両編集</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form-vehicle />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormVehicle from '../../../components/FormComponents/Vehicle'

export default {
  components: {
    FormVehicle,
  },
}
</script>

<style>
.list-name {
  font-size: 1.5rem;
}
</style>
